<template>
    <page title="人员详情">
    <Commonbanner></Commonbanner>
      <article id="page-person">
      <section class="container container-main mt-4 mb-4">
        <Collection :name="`/person/${id}`" v-slot="{ model }">
          <div class="orgdetails">
            <div class="orgdetails-header">
              <h4 class="orgdetails-title">人员信息</h4>
              <div class="tel">联系电话：<span>{{ model.phone }}</span></div>
            </div>
            <div class="orgdetails-body clearfix">
              <div class="lawwer-staff-wrap">
                <div class="pic">
                  <div rowspan="6" class="org_pic">
                    <img :src="getPersonPhotoURL(model.person_id, model.face)">
                  </div>
                </div>
                <div class="content-box">
                  <h3>{{ model.person_name }}</h3>
                  <div class="pull-left sm"> <span class="tit">执业年限：</span> <span class="cont tex-name"> <i>4年执业经验</i> </span> </div>
                  <div class="pull-left"> <span class="tit">业务专长：</span> <span class="cont"> <b class="b-tag">-</b> </span> </div>
                  <div class="pull-left sm"> <span class="tit">执业证号：</span> <span class="cont tex-name">{{ model.practice_code }}</span> </div>
                  <div class="pull-left"> <span class="tit">联系地址：</span> <span class="cont">{{ model.address }}</span> </div>
                </div>
                <div class="cont-number-box">
                  <div class="staff-auth pull-right">
                    <ul class="auth-list clearfix">
                      <li>
                        <div class="auth-text"> 咨询人次 </div>
                        <div class="circle"> 37 </div>
                      </li>
                      <li>
                        <div class="auth-text"> 满意率 </div>
                        <div class="circle"> 98%</div>
                      </li>
                      <li class="heart_li">
                        <div class="auth-text"> 点赞 </div>
                        <div @click="zanLogin('/persondetails')" class="heart circle">
                          <a-icon type="heart" theme="filled" />
                          <i class="num">{{ zanNum }}</i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="staff-info pb-5">
              <div class="tab">
                <ul class="tab-head clearfix" id="tab-affix">
                  <li><a href="#info01" class="">执业信息</a></li>
                  <li><a href="#info02" class="">服务记录</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-section" id="info01">
                    <h4 class="head">执业信息</h4>
                    <div class="body">
                      <ul class="info-list clearfix">
                        <li>
                          <label class="name">性别：</label>
                          {{ model.gender }}</li>
                        <li>
                          <label class="name">年龄：</label>
                          37</li>
                        <li>
                          <label class="name">民族：</label>
                          {{ model.nation }}</li>
                        <li>
                          <label class="name">政治面貌：</label>
                          {{ model.politics_status }} </li>
                        <li>
                          <label class="name">学历：</label>
                          {{ model.highest_degree }}</li>
                        <li>
                          <label class="name">执业机构：</label>
                          {{ model.provider_name }}</li>
                        <li>
                          <label class="name">执业证号：</label>
                          109001120130442</li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-section pr200" id="info02">
                    <h4 class="head">服务记录</h4>
                    <Collection name="/workorder" v-slot="{ collection }">
                    <div class="body">
                      <table class="table table-striped table-bordered" border="0">
                        <thead>
                        <tr>
                          <th>类型</th>
                          <th>咨询人</th>
                          <th>时间</th>
                          <th>状态</th>
                          <th>评价</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in collection" :key="item.workorder_id">
                                <th>热线咨询</th>
                                <th>{{ showPerson(item.sponsor_name) }}</th>
                                <th>{{ showTimeForpage(item.apply_time) }}</th>
                                <th>{{ item.status }}</th>
                                <!-- <th>{{ item.workorder_assess }}</th> -->
                                <th>满意</th>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                    </Collection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collection>
      </section>
      </article>
    </page>
</template>
<script>
import Collection from '@/components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
import model from '../../core/model.js'
export default {
  name: 'PersonDetails',
  mixins: [model],
  components: {
    Collection,
    Commonbanner
  },
  mounted () {
    this.workorderList()
    this.personZan()
  },
  data () {
    return {
      id: this.$route.params.id,
      workorder: [],
      formbase: {
        host_id: '',
        host_type: ''
      },
      zanNum: 0
    }
  },
  methods: {
    workorderList () {
      this.$api.get('/api/workorder').then((res) => {
        this.workorder = res.data.collection
      })
    },
    // 点赞判断登录
    zanLogin (url) {
      var that = this
      var newUrl = url + '/' + that.id
      if (that.$Cookies.get('userData') === '' || that.$Cookies.get('userData') === undefined) {
        that.$modal.confirm({
          content: h => <div>当前操作需要登录后进行</div>,
          okText: '去登录',
          cancelText: '返回',
          onOk () {
            that.$Cookies.set('gopath', newUrl)
            that.$router.push('/login')
          },
          onCancel () {
          }
        })
      } else {
        that.$message.success('点赞成功')
        var formdata = that.formbase
        formdata.host_id = that.id
        formdata.host_type = 'provider_person'
        var res = that.saveData({ url: '/praise', data: formdata })
        if (res) {
          that.zanNum = that.zanNum + 1
        }
      }
    },
    personZan () {
      this.$api.get('/api/person', {
        params: {
          person_id: this.id
        }
      }).then((res) => {
        this.zanNum = res.data.collection[0].like_num
      })
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/personnel.css"></style>
